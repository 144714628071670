html, body {
	width: 100%;
	height: 100%;
}
#map, .map {
	width: 100%;
	height: 100%;
    min-height: 680px;
}
body {
	margin: 0;
	padding: 0;
	color:#aaa;
	position: relative; /* Required for Bootstrap's scrollspy */
}
.manager {
	overflow-y:hidden;
}
.type0, .type1 {
	display: none;
}
img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}
button {
	cursor: pointer;
}
footer, section {
	box-shadow: 0 0 16px rgba(0,0,0,0.6);
}
a {
	color: #5bc0de;
}
a:hover {
	color: #F9B233;
}
.btn-icon {
	border-radius: 100%;
	flex-shrink: 0;
	height: calc(2.625rem + 2px) !important;
	overflow: hidden;
	padding: 0;
	width: calc(2.625rem + 2px) !important;
	justify-content: center;
	align-items: center;
	display: inline-flex;
}
.custom-header ul.nav.navbar-nav.links li a:hover {
	background-color: #333;
	-moz-border-radius: 6px;
	border-radius: 6px;
}
.custom-header ul.nav.navbar-nav.links li a.active {
	color: #F9B233;
	background-color: #010101;
	-moz-border-radius: 6px;
	border-radius: 6px;
}
.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
	color: #30eb8a;
}
.colorClair {
	color:#aaa;
}
.colorSombre {
	color: #272B30;
}
.colorRed {
    color: red;
}
.backYellow {
	background-color: yellow;
}
.accordion-color{
	background-color: #32383e;
	border: none;
}
.accordion-color_bourse{
	background-color: #292c2f;
	border: none;
}
caption {
    /* override bootstrap adding 8px to the top & bottom of the caption */
    padding: 0;
}
.ui-sortable-placeholder {
    /* change placeholder (seen while dragging) background color */
    background: #ddd;
}
div.table-handle-disabled {
    /* optional red background color indicating a disabled drag handle */
    background-color: rgba(255, 128, 128, 0.5);
    /* opacity set to zero for disabled handles in the dragtable.mod.css file */
    opacity: 0.7;
}
.table-handle {
	height:13px;
	background-color:#696969;
	cursor: grab;
}
/* fix cursor */
.tablesorter-blue .tablesorter-header {
    cursor: default;
}
.sorter {
    cursor: pointer;
}
.table td, .table th {
	vertical-align: middle;
}
.countdown {
    /* margin-top: 30px;
    margin-bottom: 30px;
    background-color: #080808;
    border-radius: 5px;
    padding: 60px 20px; */
    display: flex
}
.countdown-col {
    text-align: center;
    margin-right: 1%;
    margin-left: 1%;
    /* min-width: 90px;*/
    position: relative;
}
.countdown-timer {
    color: red;
    font-size: 3.2rem;
    text-transform: uppercase;
}
.countdown-label {
    color: rgba(255, 255, 255, 0.35);
    text-transform: uppercase;
    font-size: 0.7rem;
    margin-top: 1%;
}
.tab {
    display: none;
}
.tab-active {
    display: block;
}
.tab-nav {
    margin: 0px;
    padding: 0px;
    position: relative;
    bottom: -1px;
}
.tab-nav li {
    display: inline-block;
}
.tab-nav a {
    display: block;
    border: 1px solid #717171;
    border-width: 1px 1px 0 1px;
    padding: 10px 5px;
    border-radius: 5px 5px 0 0;
    background-color: #717171;
    text-decoration: none;
}
.tab-nav a:hover {
    background-color: #303030;
}
.tab-nav .tab-nav-active {
    background-color: #303030;
}
.tablesorter-header.tablesorter-reorder-helper {
    cursor: move;
}
.tablesorter-reorder-helper-bar {
    width: 1px;
    background: #000;
}
/*Time picker/
.time-picker-test{
	max-width:320px;
	display:flex;
	justify-content:center;
	flex-direction:column;
	transition:all .4s ease;
	height:0;
	overflow:hidden;
	.set-time{
		display:flex;
		justify-content:center;
		margin-bottom:15px;
	}
	.label{
		width:60px;
		margin:0 5px;
		text-align:center;
		line-height:34px;
		display:inline-style;
		a{
			display:block;
			border:1px solid #DDDDDD;
			cursor:pointer;
			font-size:28px;
			font-weight:bold;
			border-radius:3px;
			&:hover,
			&:active{
			background-color:#333;
			color:#FFFFFF;
			}
		}
		.set{
			text-align:center;
			box-sizing:border-box;
			width:100%;
			height:40px;
			line-height:34px;
			font-size:20px;
			font-weight:bold;
			border:transparent;
		}
	}
	#submitTime{
		text-align:center;
		line-height:34px;
		border:1px solid #32383e;
		width:128px;
		margin:auto;
		border-radius:3px;
		cursor:pointer;
		text-transform:uppercase;
		font-weight:bold;
	}
}
.time-picker-test.open {
	border:1px solid #32383e;
	padding:15px;
	transition:all .5s ease;
	height:auto;
	background-color:#32383e;
}
*/
.rounded {
	-moz-border-radius: 6px;
	border-radius: 6px;
}
.rounded16 {
	-moz-border-radius: 16px;
	border-radius: 16px;
}
.shadow {
	box-shadow: 0 0 6px #000;
	-moz-box-shadow: 0 0 6px #000;
	-webkit-box-shadow: 0 0 6px #000;
}
.shadow-light {
	box-shadow: 0 0 6px #FFF;
	-moz-box-shadow: 0 0 6px #FFF;
	-webkit-box-shadow: 0 0 6px #FFF;
}
.cursor {
	cursor: pointer;
}
.navbar {
	z-index: 9999;
	position: fixed;
	width: 100%;
	opacity: 0.9;
}
.custom-header {
	background-image: linear-gradient(to right, #F9B233, #088142);
	padding: 16px 16px;
	box-sizing: border-box;
	border: 0;
	border-radius: 0;
}
.navbar-brand {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
	padding: 0.75rem 0rem calc(54px - 0.75rem - 30px);
	/* padding: 0.75rem 1rem calc(54px - 0.75rem - 30px); */
}
@media (min-width:768px) {
	.navbar-brand {
		padding: 0.75rem 1rem calc(54px - 0.75rem - 30px);
	}
}
.text-warning-bourso {
	color: #F9B233 !important;
}
.text-success-bourso {
	color: #088142 !important;
}
.fa-6x {
	font-size: 16em;
}
.jumbotron a, .jumbotron a:hover {
	text-decoration: none;
}
.custom-header a.navbar-brand {
	line-height: 0.5;
	margin-right: 1%;
}
.custom-header ul.nav.navbar-nav.links li a {
	font-size: 1.2em;
	font-weight: bold;
}
.custom-header li.dropdown .dropdown-toggle {
	border-radius: 2px;
	color: #30eb8a;
	padding: 8px 14px;
	background: #5a4c4c;
	opacity: 0.9;
	border: solid 1px #333;
	margin: 0;
	-moz-border-radius: 6px;
	border-radius: 6px;
}
.bg-road {
	background: url(../img/bg-road-xy.jpg) repeat #000; color:#FFF;
}
button.btn.btn-default.login {
	font:bold 1.2em Arial, Helvetica, sans-serif;
	width:100%;
	border-radius:50%;
	height:2em;
	background:#088142;
	color: #FFF;
}
button.btn.btn-default.login:hover {
	background:#F9B233;
	color: #333;
}
.navbar .form-group {
	margin-bottom: 15px;
	width: 300px;
}
.input-login {
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: 10px;
	margin-right: 10px;
}
#dot-nav li.active {
	background-color: #088142;
}
#dot-nav li:hover {
	background-color:n#9e9991;
}
#all-the-frame {
	padding-top: 6px;
}
.opacity9 {
	opacity: 0.9;
}
.opacity8 {
	opacity: 0.8;
}
.btn .btn-primary {
    background-color: #088142 !important;
    border-color: #088142 !important;
}
.btn .btn-warning {
    background-color: #F9B233 !important;
    border-color: #F9B233 !important;
}
/* Blue ones */

.btn.btn-primary {
	background-color: #088142 !important;
	border-color: #088142 !important;
}

.modal .modal-header h4, .modal .modal-header .close{
	color: #FFF;
}
.required-input {
	color: red;
}
.text-shadow-dark {
	text-shadow:1px 1px 1px rgba(0,0,0,1);
}
.text-shadow-light {
	text-shadow:1px 1px 1px rgba(255,255,255,1);
}
@media (max-width:768px) {
	#dot-nav {
		display:none;
	}
	.carousel-item .carousel-hero img, .hero-technology img {
		width: 90%;
		margin-bottom: 6px;
	}
	.carousel-item .carousel-hero .btn, .hero-technology .btn {
		width: 96%;
		margin-bottom: 2px;
	}
	#subFormLeads .btn-block, section#leads .card {
		margin-bottom: 2px;
	}
}
section, .pricing-table, .card-section-imagia {
	padding-top: 6rem;
	padding-bottom: 6rem;
}
section#home {
	padding-top: 0;
	padding-bottom: 0;
}
section#pricing {
	background: url(../img/bg.map.light.jpg) no-repeat center;
	background-size: cover;
}
section#contact {
	background: url(../img/background-contact.jpg) no-repeat center;
	background-size: cover;
}
section#word_from_team {
	background: url(../img/a-word-from-us-bg-2.jpg) no-repeat center;
	background-size: cover;
}
.huge-quote {
	font-size: 3.6em;
}
.pricing-table .lead, .pricing-table h2 {
	color: #FFF;
	text-shadow:1px 1px 1px rgba(0,0,0,1);
}
#transporteurs .card-imagia .cover-imagia.cover-gradient {
	background: #ffffff;
	background-image: radial-gradient(circle at 50% 100%, #F9B233 0%, #088142 100%);
}
#pilotes .card-imagia .cover-imagia.cover-gradient {
	background: #ffffff;
	background-image: radial-gradient(circle at 50% 100%, #088142 0%, #F9B233 100%);
}
.card-imagia .user-imagia img {
    border: 0px;
}
section#leads h1, section#leads h2, section#leads h3, section#leads h4, section#leads h5, section#leads h6, section#leads .card p, section#leads .card ul, #answerLeadsCont .card h1, #answerLeadsCont .card h2, #answerLeadsCont .card h3, #answerLeadsCont .card h4, #answerLeadsCont .card h5, #answerLeadsCont .card h6 {
	color: #FFF;
	text-align: center;
}
.card {
	margin-top: 6px;
}
#answerLeadsCont .card p, #answerLeadsCont .card ul {
	color: #FFF;
	text-align: left;
}
#answerLeadsCont .card {
	margin-top: 2px;
}
section#leads hr {
	box-shadow: 0 0 2px 1px #088142;
}
#leadsCont .leadinfo .alert {
	padding: 0.1rem;
	font-size: x-small;
	margin-bottom: 0.1rem;
}
section#drivers h1 {
	color: #FFF;
}
.accordion, .accordion h2, .accordion h4 {
	color: #DEDEDE;
}
.accordion .btn, .modal .btn-block {
	width: 100%;
	font-weight: bold;
}
.site-footer {
	padding: 20px 0;
	text-align: center;
}

.site-footer h5 {
	color: inherit;
	font-size: 20px;
}

a.go-up-fixed {
	display: none;
	position: fixed;
	bottom: 6px;
	right: 6px;
	width: 32px;
	border: none;
	font-size: 20px;
	border-radius: 50%;
	margin: 4px;
	color: #fff;
	text-align: center;
	background-color: #088142;
	height: 32px;
	opacity: 0.8;
	line-height: 32px;
}
a.add-lead-fixed {
	display: block;
	position: fixed;
	bottom: 42px;
	right: 6px;
	width: 32px;
	border: none;
	font-size: 20px;
	border-radius: 50%;
	margin: 4px;
	color: #fff;
	text-align: center;
	background-color: #F9B233;
	height: 32px;
	opacity: 0.8;
	line-height: 32px;
}
.was-validated input:invalid, .was-validated select:invalid, .was-validated textarea:invalid {
	border: 1px solid red;
	box-shadow: 0 0 2px 1px red;
}
.was-validated input:valid, .was-validated select:valid, .was-validated textarea:valid {
	border: 1px solid green;
	box-shadow: 0 0 2px 1px green;
}

.site-footer .social-icons a:hover, a.go-up-fixed:hover {
	opacity:1;
}

.site-footer .social-icons a {
	display:inline-block;
	width:32px;
	border:none;
	font-size:20px;
	border-radius:50%;
	margin:4px;
	color:#fff;
	text-align:center;
	background-color:#798FA5;
	height:32px;
	opacity:0.8;
	line-height:32px;
}

@media (min-width:768px) {
	.site-footer .social-icons {
		text-align:right;
	}
	footer {
		margin-top: 0px;
	}
	.site-footer h5 {
		text-align:left;
	}
}

button.clearBtn {
	position: absolute;
	top: 36px;
	right: 6px;
	z-index: 1000;
	font-weight: bold;
	font-size: 16px;
	border: 2px solid #FFBF00;
	-moz-border-radius: 16px;
	border-radius: 16px;
}

/* From station css */

.manager p, .manager h1, .manager h2, .manager h3, .manager h4, .manager h5, .manager h6 {
	margin: 0px;
	padding: 0px;
}
.manager p {
    font-family: "proxima-nova","NeutraText",sans-serif;
    font-size: 1.2em;
    font-weight: bold;
    line-height: 1em;
}
.manager a {
	text-decoration: none;
}
.manager ul, .manager li {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}
.cleaner {
	clear: both;
	height: 1px;
	overflow: hidden;
}
.spacer {
	clear: both;
	height: 6px;
	overflow: hidden;
}
.spacer32 {
	clear: both;
	height: 32px;
	overflow: hidden;
}
.large {
	width: 96%;
}
#left {
	color: #FFF;
}
#search-box, #book-box {
	display: none;
}
.ui-datepicker {
	z-index: 10000 !important;
}
.ui-datepicker select {
	z-index: 10010;
}
.pac-container {
	font-size: 19px;
	z-index: 10000;
}
/* To override Jq UI with old dark theme (uncomplete) */
/*
.ui-widget-content {
	border: 1px solid #666;
	background-image: linear-gradient(to bottom, #333, #000);
	color: #fff;
}
*/
.ui-resizable {
    position: absolute !important;
}
.wrap-right {
    color: #FFF;
    background-image: linear-gradient(to bottom, #333, #000);
    box-shadow: 0 0 6px #333;
    -moz-box-shadow: 0 0 6px #333;
    -webkit-box-shadow: 0 0 6px #333;
    padding: 6px;
    width: 26%;
    max-width: 600px;
    height: auto;
    position: absolute;
    top: 60px !important;
    right: 6px !important;
    z-index: 1000;
    font-weight: bold;
    font-size: 16px;
    border: 2px solid #FFBF00;
    -moz-border-radius: 16px;
    border-radius: 16px;
}
.wrap-top {
	color: #FFF;
	background-image: linear-gradient(to bottom, #333, #000);
	box-shadow: 0 0 6px #333;
	-moz-box-shadow: 0 0 6px #333;
	-webkit-box-shadow: 0 0 6px #333;
	padding: 6px;
	width: 98% !important;
	min-width: 1680px !important;
	height: 98% !important;
	position: absolute;
	top: 1% !important;
	left: 1% !important;
	z-index: 1000;
	font-weight: bold;
	font-size: 16px;
	border: 2px solid #FFBF00;
	-moz-border-radius: 16px;
	border-radius: 16px;
}
.wrap-minimal {
    color: #FFF;
    background-image: linear-gradient(to bottom, #333, #000);
    box-shadow: 0 0 6px #333;
    -moz-box-shadow: 0 0 6px #333;
    -webkit-box-shadow: 0 0 6px #333;
    padding: 6px;
    width: auto;
    height: auto;
    position: absolute;
    top: 60px !important;
    right: 6px !important;
    z-index: 1000;
    font-weight: bold;
    font-size: 16px;
    border: 2px solid #FFBF00;
    -moz-border-radius: 16px;
    border-radius: 16px;
}
.wrap-down {
	color: #FFF;
	background-image: linear-gradient(to bottom, #333, #000);
	box-shadow: 0 0 6px #333;
	-moz-box-shadow: 0 0 6px #333; 
	-webkit-box-shadow: 0 0 6px #333;
	padding: 2px;
	max-width: 84%;
	height: 36px;
	line-height: 32px;
	position: absolute;
	bottom: 6px;
	left: 100px;
	z-index: 999;
	font-weight: bold;
	font-size: 1em;
	border: 2px solid #FFBF00;
	-moz-border-radius: 16px;
	border-radius: 16px;
	text-align: left;
}
#statusBar * {
	display: inline-block;
	vertical-align: middle;
}
.vline {
	border-right: 2px solid #FFBF00;
	padding: 0px 2px 0px 2px;
	height: 100%;
}
.mailLink {
	width: 32px;
	height:32px;
}
.locsel {
	display: none;
	color: #333;
	padding: 6px;
	margin: 2px;
	-moz-box-shadow:inset 2px 2px 5px #ccc;
	box-shadow:inset 2px 2px 5px #ccc;
	-moz-border-radius: 6px;
	border-radius: 6px;
	border: 1px solid #666;
	width: 60%;
	height: auto;
	position: absolute;
	top: 2px;
	left: 90px;
	z-index: 999;
	font-weight: bold;
	font-size: 16px;
}
.dc {
	width: 36px;
	position: absolute;
	bottom: 6px;
	left: 6px;
	z-index: 1010;
}
.wipe {
	width: 36px;
	position: absolute;
	bottom: 6px;
	left: 48px;
	z-index: 1010;
}
.depsel {
	position: absolute;
	bottom: 36px;
	right: 6px;
	z-index: 999;
}
.main-nav {
	font-size: 12px;
	cursor: move;
}
.sub-menu {
	position: relative;
	left: 36px;
	margin-top: -18px;
	height: 16px;
	text-align: left;
	font-size: 1em;
	font-weight: bold;
	padding: 0.6em;
	margin-bottom: 6px;
}
.sub-menu li {
	display: inline-block;
}
.sub-menu li a.in {
	border: 1px solid #003eff;
	background: #007fff;
	font-weight: bold;
	font-size: 1em;
	padding: 0.2em;
	color: #fff;
	-moz-border-radius: 6px 6px 0px 0px;
	border-radius: 6px 6px 0px 0px;
}
.ui-draggable-handle {
	cursor: move;
}
.ui-tabs {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}
.diary a {
	color: #28a745;
}
.diary a:hover {
	color: #FFBF00;
}
#calendar {
	/* max-width: 130vh; */
	margin: 0 auto;
}
.dragger {
	background: #FFBF00;
	height: 10px;
	width: 100%;
	cursor: pointer;
}
#popup {
    width:90%;
    height: auto;
	overflow: auto;
    display: none;
    position: fixed;
	top: 6%;
	left: 6%;
    background: #333;
	background-color: rgba(60, 60, 60, 0.6);
	padding: 2%;
    background: #FFF;
    border: 1px solid #666;
    border: 1px solid #555;
	-moz-border-radius: 16px;
	border-radius: 16px;
    box-shadow: 2px 2px 16px #222;
    z-index: 999999;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
#popupmax {
    width: 90%;
    height: 90%;
	overflow: auto;
    display: none;
    position: fixed;
	top: 5%;
	left: 5%;
    background: #333;
	background-color: rgba(60, 60, 60, 0.6);
	padding: 2%;
    border: 1px solid #666;
    border: 1px solid #555;
	-moz-border-radius: 16px;
	border-radius: 16px;
    box-shadow: 2px 2px 16px #222;
    z-index: 99999;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
#popupmin {
    width: 68%;
    height: auto;
	overflow: auto;
    display: none;
    position: fixed;
	top: 8%;
	left: 16%;
    background: #333;
	background-color: rgba(60, 60, 60, 0.6);
	padding: 2%;
    border: 1px solid #666;
    border: 1px solid #555;
	-moz-border-radius: 16px;
	border-radius: 16px;
    box-shadow: 2px 2px 360px #222;
    z-index: 999999;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
#inPopup {
	width: 100%;
	height: 100%;
	overflow: visible;
	background: #FFF;
	opacity: 1;
	padding: 2px;
}
.popBackMax {
	position: absolute;
	top: 5%;
	right: 5%;
    z-index: 999999;
	cursor: pointer;
}
.popBack {
	float: right;
}
.leaflet-control-layers-list {
	padding: 2em;
	min-height: 10vh;
}
input[type=checkbox] {
	cursor: pointer;
}
ul.popers a {
	display: inline;
}
.expends {
	display: inline-block;
	width: 96%;
	color: #FFBF00;
	cursor: pointer;
}
#details, #book, #histBlock {
	display: none;
}
.back {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1000;
}
.manager em {
	color: #F00;
}
::-webkit-input-placeholder {
	color: #333;
}

:-moz-placeholder { /* Firefox 18- */
	color: #333;
}

::-moz-placeholder {  /* Firefox 19+ */
	color: #333;
}

:-ms-input-placeholder {
	color: #333;
}
.manager input, .manager select, .manager textarea {
	font-size: 16px;
	color: #333;
	padding: 6px;
	margin: 2px;
	-moz-box-shadow: inset 2px 2px 5px #ccc;
	box-shadow: inset 2px 2px 5px #ccc;
	-moz-border-radius: 6px;
	border-radius: 6px;
	border: 1px solid #666;
}
button:disabled {
	cursor: no-drop;
}
#infos input, #infos select, #infos textarea, #modBook input, #modBook select, #modBook textarea, #conatctForm input, #conatctForm select, #conatctForm textarea {
	width: 96%;
}
#infos input, #modBook input {
	width: 90%;
}
#slider {
	width: 96%;
}
#infos button.classic, #conatctForm button.classic, #flashForm button.classic, #newsForm button.classic, #popupmin button.classic, #modBook button {
	font-size: 1em;
	font-weight: bold;
	color: #FFF;
	padding: 6px;
	-moz-box-shadow:inset 2px 2px 5px #000;
	box-shadow:inset 2px 2px 5px #000;
	-moz-border-radius: 6px;
	border-radius: 6px;
	border: 1px solid #FFF;
	background: #666;
	width: 96%;
}
#statusBar button.classic {
	font-size: 1em;
	font-weight: bold;
	color: #FFF;
	padding: 6px;
	-moz-box-shadow:inset 2px 2px 5px #000;
	box-shadow:inset 2px 2px 5px #000;
	-moz-border-radius: 6px;
	border-radius: 6px;
	border: 1px solid #FFF;
	background: #666;
}
#infos button.classic:hover, #statusBar button:hover, #modBook button:hover, #conatctForm button.classic:hover, #flashForm button.classic:hover, #newsForm button.classic:hover {
	color: #FFBF00;
	background: #333;
}
button.cancel {
	position: absolute;
	bottom: 38px;
	left: 46px;
	height: 36px;
	z-index: 1010;
	font-size: 1em;
	font-weight: bold;
	color: #000;
	padding: 6px;
	-moz-box-shadow: 2px 2px 5px #000;
	box-shadow: 2px 2px 5px #000;
	-moz-border-radius: 6px;
	border-radius: 6px;
	border: 1px solid #000;
	background: #F00;
}
	button.cancel:hover {
		-moz-box-shadow:inset 2px 2px 5px #333;
		box-shadow:inset 2px 2px 5px #333;
		background: #FFF;
	}
input.Sender, button.Sender {
	font-size: 1em;
	font-weight: bold;
	color: #FFF;
	padding: 6px;
	-moz-box-shadow:inset 2px 2px 5px #000;
	box-shadow:inset 2px 2px 5px #000;
	-moz-border-radius: 6px;
	border-radius: 6px;
	border: 1px solid #000;
	cursor: pointer;
	background: #666;
	margin: 2px;
}
input.Sender:hover, button.Sender:hover {
	-moz-box-shadow: 2px 2px 5px #000;
	box-shadow: 2px 2px 5px #000;
	background: #333;
}
input.Connect {
	font-size: 1em;
	font-weight: bold;
	color: #000;
	padding: 6px;
	-moz-box-shadow: 2px 2px 5px #333;
	box-shadow: 2px 2px 5px #333;
	-moz-border-radius: 6px;
	border-radius: 6px;
	border: 1px solid #000;
	cursor: pointer;
	background: #FFBF00;
}
input.Connect:hover {
	-moz-box-shadow:inset 2px 2px 5px #333;
	box-shadow:inset 2px 2px 5px #333;
	background: #FFF;
}
input.Eraser, button.Eraser {
	font-size: 1em;
	font-weight: bold;
	color: #000;
	padding: 6px;
	-moz-box-shadow: inset 2px 2px 5px #000;
	box-shadow: inset 2px 2px 5px #000;
	-moz-border-radius: 6px;
	border-radius: 6px;
	border: 1px solid #000;
	cursor: pointer;
	background: #F00;
}
button.Eraser {
	width: 96%;
}
input.Eraser:hover, button.Eraser:hover {
	-moz-box-shadow:inset 2px 2px 5px #333;
	box-shadow:inset 2px 2px 5px #333;
	background: #FFF;
}
/* For iPhones and Mobile */
@media all and (max-width:900px) { 
	#wrap, #footer {
		width: 96%;
	}
	#popupmin {
		width: 84%;
		height: 84%;
		position: fixed;
		top: 8%;
		left: 8%;
	}
}