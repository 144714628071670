div.jumbotron.carousel-hero {
  color:#fff;
  text-align:center;
  background-size:cover;
  background-position:center;
  padding-top:100px;
  padding-bottom:100px;
  margin-bottom:0;
}

div.jumbotron.hero-bourso-light {
  background-image:url('../../assets/img/bg.map.light.jpg');
}

div.jumbotron.hero-bourso-grey {
  background-image:url('../../assets/img/bg.map.grey.jpg');
}

div.jumbotron.hero-bourso-dark {
  background-image:url('../../assets/img/bg.map.dark.jpg');
}

h1.hero-title, h2.hero-title, h3.hero-title {
  text-shadow:0 3px 3px rgba(0,0,0,0.4);
  color:#fff;
}

p.hero-subtitle {
  font-size: 1.2em;
  max-width:800px;
  margin:25px auto;
  padding:10px 15px;
  color:#fff;
}

.plat {
  border-radius:0px;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow:0 0 0 0 #048f83!important;
}

.btn-primary:hover {
  color:#fff;
  background-color:#016090;
  border-color:#016090;
}

.btn.btn-primary {
  background-color:#048f83!important;
  border-color:#048f83!important;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow:0 0 0 .2rem rgba(0,123,255,.5);
}

* {
  /*box-sizing:inherit;*/
}

*:before, *:after {
  /*box-sizing:inherit;*/
}

span {
  font-style:inherit;
  font-weight:inherit;
}

