/* 
 * dragtable
 *
 * @Version 2.0.15
 *
 * default css
 *
 */


/*##### the dragtable stuff #####*/

.dragtable-sortable {
    list-style-type: none;
    margin: 0;
    padding: 0;
    -moz-user-select: none;
}

.dragtable-sortable li {
    margin: 0;
    padding: 0;
    float: left;
    font-size: 1em;
    background: white;
}

.dragtable-sortable th,
.dragtable-sortable td {
    border-left: 0px;
}

.dragtable-sortable li:first-child th,
.dragtable-sortable li:first-child td {
    border-left: 1px solid #CCC;
}

.ui-sortable-helper {
    opacity: 0.7;
    filter: alpha(opacity=70);
}

.ui-sortable-placeholder {
    -moz-box-shadow: 4px 5px 4px #C6C6C6 inset;
    -webkit-box-shadow: 4px 5px 4px #C6C6C6 inset;
    box-shadow: 4px 5px 4px #C6C6C6 inset;
    border-bottom: 1px solid #CCCCCC;
    border-top: 1px solid #CCCCCC;
    visibility: visible !important;
    background: #EFEFEF !important;
    visibility: visible !important;
}

.ui-sortable-placeholder * {
    opacity: 0.0;
    visibility: hidden;
}