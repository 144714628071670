div.jumbotron.hero-technology {
	color:#fff;
	text-align:center;
	background:url('../img/bg.map.dark.jpg');
	/* background: url(../img/truck-road-bg.jpg) no-repeat center; */
	background-size:cover;
	background-position:center top;
	padding-top:100px;
	padding-bottom:100px;
	background-attachment:local;
}
h1.hero-title, h2.hero-title, h3.hero-title {
	text-shadow: 2px 2px 2px rgba(0,0,0,0.6);
	color: #fff;
	font-weight: 500;
}
p.hero-subtitle {
	font-size: 1.2em;
	max-width:800px;
	margin: 25px auto;
	padding: 10px 15px;
	background-color: rgba(0,0,0,0.5);
	color: #fff;
	text-shadow: 0 1px 1px rgba(0,0,0,0.9);
	font-weight: 1000;
}
