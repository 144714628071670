.custom-header {
  background-image: linear-gradient(to right, #DE0464, #EC6609);
  padding:20px 40px;
  box-sizing:border-box;
  border:0;
  border-radius:0;
}

.custom-header a.navbar-brand {
  color:#FFF;
  font:normal 28px Cookie, Arial, Helvetica, sans-serif;
  line-height:0.5;
  margin-right:40px;
}

.custom-header a.navbar-brand span {
  color:#bc40df;
}

.custom-header ul.nav.navbar-nav.links li a {
  font:1.2em Arial, Helvetica, sans-serif;
  opacity:0.9;
  text-decoration:none;
  color:#FFF;
  border:0;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.dropdown .nav-link {
  font:1.2em Arial, Helvetica, sans-serif;
}
.custom-header ul.nav.navbar-nav li a:hover {
  opacity:1;
}

.custom-header .badge {
  font-size:11px;
  vertical-align: top;
  font-weight:normal;
}

.custom-header li.dropdown, .custom-header li.dropdown.open {
  background:#5a4c4c;
  color:#EC6609;
}

.custom-header li.dropdown .dropdown-toggle {
  border-radius:2px;
  color:#EC6609;
  padding:8px 14px;
  background:#5a4c4c;
  opacity:0.9;
  border:solid 1px #333;
  margin:0;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.custom-header li.dropdown .dropdown-toggle:focus, .custom-header li.dropdown .dropdown-toggle:hover, .custom-header li.dropdown .dropdown-toggle:active {
  background:#5a4c4c;
  color:#fff;
}

.custom-header ul.nav.navbar-nav.navbar-right .dropdown li a {
  font:bold 14px Arial, Helvetica, sans-serif;
}

img.dropdown-image {
  border-radius:50%;
  width:32px;
  margin-left:10px;
}

.custom-header .dropdown ul {
  background-color:#333;
  border:0;
  min-width:140px;
}

.custom-header .dropdown ul li {
  background-color:#333;
  padding:7px;
  text-align:center;
}

.custom-header .dropdown ul li a {
  background:#333;
  border-radius:2px;
  color:#fff;
  padding:0;
}

.custom-header .dropdown ul li a:active, .custom-header .dropdown ul li a:focus, .custom-header .dropdown ul li a:hover {
  background:#333;
}

.custom-header .dropdown ul li.active a {
  color:#EC6609 !important;
}
/*
.navbar-nav input[type=text], .navbar-nav input[type=password] {
	font-size: 16px;
	color: #333;
	-moz-box-shadow:inset 2px 2px 5px #ccc;
	box-shadow:inset 2px 2px 5px #ccc;
	-moz-border-radius: 6px;
	border-radius: 6px;
	border: 1px solid #666;
}
*/